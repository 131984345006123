import React from 'react'
import './success.scss'
import { FaRegCheckCircle } from "react-icons/fa"
import { Link } from "@reach/router";
import { IoIosArrowRoundBack } from "react-icons/io"; 

const Success = (props) => {
    return (
        <div className="wd-success">
            <div className="wd-success__message-container">
                <h2 className="wd-success__message">{props.message}</h2>
                <FaRegCheckCircle className="wd-success__icon"/>
            </div>

            <div className="wd-success__link">
                <Link to="/" className="wd-button wd-button--1"> 
                    <IoIosArrowRoundBack className="wd-button__icon wd-button__icon--3"/> Retour à la page d'accueil
                </Link>
            </div>   
        </div>
    )
} 

export default Success